$primaryColor: #2876D0;
$primaryColorOverlay: rgba(40, 118, 208, 0.12);
$secondaryColor: #0F3551;
$accentColor: #E4032E;
$grayDarkColor: #0F3551;

$logoGradient: linear-gradient(45deg, $primaryColor 30%, $primaryColor);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") center / contain no-repeat;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg") center / contain no-repeat;
$logoBackgroundLanding: url("/images/logo/cloud-transparent-mono-long.svg") center / contain no-repeat;
$logoBackgroundMobile: url("/images/logo/brb-logo-mono-mobile.svg") center / contain no-repeat;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar{
    .sc-branding{
        padding: 0;
        .cloud-logo{
            width: 100%;
            margin: 0;
            padding: 30px;
        }
        .sc-title {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .sc-branding{
            padding: 0;
            .cloud-logo{
                background: $logoBackgroundTablet;
            }
        }
    }
}
body {
    header.top-margin {
        margin-top: 0;
    }
    header {


        .navbar-light {
            #title {
                padding: 0 !important;
            }

            .cloud-logo {
                background: $logoBackgroundLanding !important;
                margin-right: 0 !important;
                margin-left: -5rem !important;
                height: 3.5rem !important;
                width: 21.5rem !important;
            }

            @media (max-width: 991px) {
               .cloud-logo {
                    background: $logoBackgroundTablet;
                    margin-left: 0.5em!important;
                    position: absolute;
                    left: 0;
                    width: 208px !important;
                }
            }
            @media (max-width: 557px) {
                .cloud-logo {
                    background: $logoBackgroundMobile !important;
                    width: 112px !important;
                    height: 50px;
                    margin-top: -8px;
                 }
             }
        }
    }
}

.section-imprint.loggedout ul {
	list-style-type: none!important;
	margin: 0!important;
}

.made-with-love {
    .poweredby-logo {
        height: 1em;
        vertical-align: middle;
    }
}

.primary-color{
    color: $primaryColor;
    display: none;
}
.secondary-color {
    color: $secondaryColor;
    display: none;
}
@font-face {
	font-family: 'PT Sans';
	src: url('../../fonts/ptsans/ptsans-regular.eot?v=1.0.0');
	src: url('../../fonts/ptsans/ptsans-regular.eot?v=1.0.0#iefix') format('embedded-opentype'),
		 url('../../fonts/ptsans/ptsans-regular.woff2?v=1.0.0') format('woff2'),
		 url('../../fonts/ptsans/ptsans-regular.woff?v=1.0.0') format('woff'),
		 url('../../fonts/ptsans/ptsans-regular.ttf?v=1.0.0') format('truetype'),
		 url('../../fonts/ptsans/ptsans-regular.svg?v=1.0.0#pt_sansregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../../fonts/ptsans/ptsans-italic.eot?v=1.0.0');
	src: url('../../fonts/ptsans/ptsans-italic.eot?v=1.0.0#iefix') format('embedded-opentype'),
		 url('../../fonts/ptsans/ptsans-italic.woff2?v=1.0.0') format('woff2'),
		 url('../../fonts/ptsans/ptsans-italic.woff?v=1.0.0') format('woff'),
		 url('../../fonts/ptsans/ptsans-italic.ttf?v=1.0.0') format('truetype'),
		 url('../../fonts/ptsans/ptsans-italic.svg?v=1.0.0#pt_sansregular') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../../fonts/ptsans/ptsans-bold.eot?v=1.0.0');
	src: url('../../fonts/ptsans/ptsans-bold.eot?v=1.0.0#iefix') format('embedded-opentype'),
		 url('../../fonts/ptsans/ptsans-bold.woff2?v=1.0.0') format('woff2'),
		 url('../../fonts/ptsans/ptsans-bold.woff?v=1.0.0') format('woff'),
		 url('../../fonts/ptsans/ptsans-bold.ttf?v=1.0.0') format('truetype'),
		 url('../../fonts/ptsans/ptsans-bold.svg?v=1.0.0#pt_sansregular') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../../fonts/ptsans/ptsans-bold-italic.eot?v=1.0.0');
	src: url('../../fonts/ptsans/ptsans-bold-italic.eot?v=1.0.0#iefix') format('embedded-opentype'),
		 url('../../fonts/ptsans/ptsans-bold-italic.woff2?v=1.0.0') format('woff2'),
		 url('../../fonts/ptsans/ptsans-bold-italic.woff?v=1.0.0') format('woff'),
		 url('../../fonts/ptsans/ptsans-bold-italic.ttf?v=1.0.0') format('truetype'),
		 url('../../fonts/ptsans/ptsans-bold-italic.svg?v=1.0.0#pt_sansregular') format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}
